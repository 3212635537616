import React from "react"

function BandcampTrack(props) {
    const albumNo = props.albumNo
    const dim = props.dim
    return(
        <iframe 
             title={albumNo}
             style={{border: "0", width: `${dim}px`, height: `${dim}px`}}
             src={`https://bandcamp.com/EmbeddedPlayer/track=${albumNo}/size=large/bgcol=ffffff/linkcol=333333/minimal=true/transparent=true/`} 
             seamless>
        </iframe>
        
    )
}

export default BandcampTrack