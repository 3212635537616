import React from "react"
import Bandcamp from "../../Generic/Bandcamp"
import BandcampTrack from "../../Generic/BandcampTrack"
import ContentSq from "../../Generic/ContentSq"
import Youtube from "../../Generic/Youtube"
import SqImgLink from "../../Generic/SqImgLink"
import SqH2 from "../../Generic/SqH2"


function MediaInterpreter(props) {
    const dim=props.dim;

    // COLLECT MEDIA
    const media = props.items.map( (item) => {
        let returnItm
        if(item.type === "dateHeading"){
            returnItm = (
                <ContentSq key={item.itemDate} dim={dim} leftBorder={true}>
                    <SqH2 title={item.itemDate} dim={dim}/>
                </ContentSq>
            )
        }
        else if(item.type === "flexLineBreak"){
            returnItm = (
                <div className="flexLineBreak" key={item.itemDate}/>
            )
        }
        else if (item.type === "bandcamp"){
            returnItm = (
                <ContentSq key={item.itemDate} dim={dim} hideBorder={false}>
                    <Bandcamp 
                        dim={dim}
                        albumNo={item.albumNo} 
                    />
                </ContentSq>
            )
        }
        else if (item.type === "bandcampTrack"){
            returnItm = (
                <ContentSq key={item.itemDate} dim={dim} hideBorder={false}>
                    <BandcampTrack 
                        dim={dim}
                        albumNo={item.albumNo} 
                    />
                </ContentSq>
            )
        } 
        else if (item.type === "youtube"){
            returnItm = (
                <ContentSq key={item.itemDate} dim={dim} hideBorder={false}>
                    <Youtube 
                        dim={dim}
                        video={item.video} 
                    />
                </ContentSq>
            )
        }
        else if (item.type === "imgLink"){
            returnItm = (
                <ContentSq key={item.itemDate} dim={dim} hideBorder={false}>
                    <SqImgLink 
                        dim={dim}
                        src={`Albums/${item.img}.jpg`}
                        link={item.link}
                    />
                </ContentSq>
            )
        }
        else {returnItm=(<div></div>)}
        return ( returnItm )
    })

    return(
        <div className="mediaInterpreter">
            {media}
        </div>
    )
}

export default MediaInterpreter