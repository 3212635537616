const projectsData = {
    titleEng: "Projects",
    titleCym: "Prosiectau",
    contentEng: [
        {
            type: "h3",
            title: "Onin"
        },
        {   
            type: "p",
            paragraph: "Onin is a long-standing collaborative project between Joe Wright and James L Malone, exploring unstable systems and atypical interactions. Born of the London improvisation workshops run by AMM co-founder Eddie Prévost, Onin began from explorations of acoustic instruments, but has since evolved towards networked analog and digital systems, where both players play different aspects of the same hybrid musical instrument. The duo have released music on experimental labels Verz Imprint (London) and FLUF (Copenhagen), as well as releasing on their own co-founded label, FD."
        },
        {
            itemTitle: "SOGgy",
            artist: "Onin",
            type: "bandcamp",
            itemDate: 202304,
            albumNo: 374984259 ,
            filter: "mus"
        },
        {
            itemTitle: "O1N1I1U1",
            artist: "Onin",
            itemDate: 202003,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "3966218909",
        },
        {
            itemTitle: "non",
            artist: "Onin",
            itemDate: 201908,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "225157356",
        },
        {
            itemTitle: "SICUM AT-EM",
            artist: "Onin",
            itemDate: 2018101,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "2230370983",
        },
        {
            type: "bandcamp",
            itemTitle: "Errery",
            artist: "Onin",
            itemDate: 2017071,//year-month
            albumNo: "532479034",
        },
        {
            type: "h3",
            title: "Solo"
        },
        {
            type: "p",
            paragraph: "Joe Wright has been performing and producing solo work since the release of his first album, Stratigraphy, in 2015. Each release explores a musical tool through improvisation, ranging from acoustic saxophone through to digital musical interfaces and software. His latest release, Soliloquy, tests the limits of a near-broken release of Integra Live." 
        },
        {
            itemTitle: "Sad DJ",
            artist: "Joe Wright",
            type: "bandcamp",
            itemDate: 202209,
            albumNo: 3160389612,
            filter: "mus"
        },
        {
            itemTitle: "Soliloquy",
            artist: "Joe Wright",
            itemDate: 202009,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "1406202218",
        },
        {
            itemTitle: "Saxophone & Dynamic Feedback Solos",
            artist: "Joe Wright",
            itemDate: 201902,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "3557820557",
        },
        {
            itemTitle: "Yarrow II - ClariNot Solos",
            artist: "Joe Wright",
            itemDate: 201708,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "41111320",
        },
        {
            itemTitle: "Yarrow",
            artist: "Joe Wright",
            itemDate: 2015112,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "40222183",
        },
        {
            itemTitle: "Stratigraphy",
            artist: "Joe Wright",
            itemDate: 201412,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "361874676",
        },
    ],
    contentCym: [
        {
            type: "h3",
            title: "Onin"
        },
        {   
            type: "p",
            paragraph: "Mae Onin yn brosiect cydweithredol hirsefydlog rhwng Joe Wright a James L Malone, sy'n archwilio systemau ansefydlog a rhyngweithio annodweddiadol. Yn hannu o weithdai byrfyfyr Llundain a gynhaliwyd gan gyd-sylfaenydd AMM Eddie Prévost, cychwynnodd Onin archwilio offerynnau acwstig, ond ers hynny mae wedi esblygu tuag at systemau analog a digidol wedi'u rhwydweithio, lle mae'r ddau chwaraewr yn chwarae gwahanol agweddau ar yr un offeryn cerdd hybrid. Mae'r ddeuawd wedi rhyddhau cerddoriaeth ar labeli arbrofol Verz Imprint (Llundain) a FLUF (Copenhagen), yn ogystal â rhyddhau ar eu label cyd-sylfaenol eu hunain, FD."
        },
        {
            itemTitle: "SOGgy",
            artist: "Onin",
            type: "bandcamp",
            itemDate: 202304,
            albumNo: 374984259 ,
            filter: "mus"
        },
        {
            itemTitle: "O1N1I1U1",
            artist: "Onin",
            itemDate: 202003,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "3966218909",
        },
        {
            itemTitle: "non",
            artist: "Onin",
            itemDate: 201908,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "225157356",
        },
        {
            itemTitle: "SICUM AT-EM",
            artist: "Onin",
            itemDate: 2018101,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "2230370983",
        },
        {
            type: "bandcamp",
            itemTitle: "Errery",
            artist: "Onin",
            itemDate: 2017071,//year-month
            albumNo: "532479034",
        },
        {
            type: "h3",
            title: "Unigol"
        },
        {
            type: "p",
            paragraph: "Mae Joe Wright wedi bod yn perfformio a chynhyrchu gwaith unigol ers rhyddhau ei albwm cyntaf, Stratigraphy, yn 2015. Mae pob datganiad yn archwilio teclyn cerdd trwy fyrfyfyr, yn amrywio o sacsoffon acwstig i ryngwynebau a meddalwedd cerddorol digidol. Mae ei ryddhad diweddaraf, Soliloquy, yn profi terfynau rhyddhau Integra Live sydd bron â thorri." 
        },
        {
            itemTitle: "Dysga di",
            artist: "Joe Wright",
            type: "bandcampTrack",
            itemDate: 202411,
            albumNo: 2466191889,
            filter: "mus"
        },
        {
            itemTitle: "Sad DJ",
            artist: "Joe Wright",
            type: "bandcamp",
            itemDate: 202209,
            albumNo: 3160389612,
            filter: "mus"
        },
        {
            itemTitle: "Soliloquy",
            artist: "Joe Wright",
            itemDate: 202009,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "1406202218",
        },
        {
            itemTitle: "Saxophone & Dynamic Feedback Solos",
            artist: "Joe Wright",
            itemDate: 201902,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "3557820557",
        },
        {
            itemTitle: "Yarrow II - ClariNot Solos",
            artist: "Joe Wright",
            itemDate: 201708,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "41111320",
        },
        {
            itemTitle: "Yarrow",
            artist: "Joe Wright",
            itemDate: 2015112,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "40222183",
        },
        {
            itemTitle: "Stratigraphy",
            artist: "Joe Wright",
            itemDate: 201412,//year-month
            type: "bandcamp",//bandcamp, imgLink, youtube
            albumNo: "361874676",
        },
    ]

}

export default projectsData